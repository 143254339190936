<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"><svg v-svg symbol="x" size="0 0 17 17" role="presentation" /></span>
    </button>
    <div class="modal-body">
      <div class="title-h2 pb-2">{{ $t("columns") }}</div>

      <div v-for="category in categories" :key="`category-${category}`">
        <div class="row">
          <div class="col-12">
            <h4 class="category-title">{{ $t(`statistic.categories.${category}`) }}</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-6 form-item__field" v-for="(item, index) in filteredColumns(category)" :key="`field-${index}`">
            <label class="check-label" :disabled="item.disabled">
              <div class="check-box-block">
                <span class="checkSpan" :class="{ active: item.checked, disabled: item.disabled }">
                  <svg v-svg symbol="check" v-if="item.checked" size="0 0 15 15" role="info" class="check" />
                </span>
                <span class="text">{{ formatLabel(item.label) }}</span>
                <input :disabled="item.disabled" hidden v-model="item.checked" type="checkbox" class="debugCB" @change="toggleSelectOnce(item)" />
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="sep _h20"></div>
      <div class="row">
        <div class="col-6">
          <button class="btn _mob100" @click="changeColumns">
            {{ $t("save") }}
          </button>
        </div>
        <div class="col-6 d-flex align-items-center">
          <button class="link" @click="toggleAll(!setFilter)">
            <strong>{{ setAllTitle }}</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/_tubs.scss";
import "@/assets/css/_code.scss";
import "@/assets/css/_modal.scss";

export default {
  name: "TableColumnsModal",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      categories: [],
    };
  },
  computed: {
    setAllTitle() {
      return this.setFilter ? this.$t("turn_all_off") : this.$t("turn_all_on");
    },
    setFilter() {
      let counter = 0;
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i].checked) {
          counter++;
        }
      }
      return counter > 1;
    },
  },
  methods: {
    filteredColumns(category) {
      return this.value.filter((el) => el.available && el.category === category);
    },
    toggleSelectOnce(evt) {
      evt.hidden = !evt.checked;
    },
    removeAllChange() {
      this.$emit("removeAllChanges");
    },
    toggleActive() {
      this.$emit("toggleAll", !this.setFilter);
    },
    changeColumns() {
      this.$emit("updateColumns", this.value);
      this.$emit("closeModal");
    },
    toggleAll(evt) {
      if (evt) {
        this.value.forEach((e) => (e.checked = true));
        this.value.forEach((e) => (e.hidden = false));
      } else {
        this.value.forEach((e) => (e.field !== "first_column" ? (e.checked = false) : (e.checked = true)));
        this.value.forEach((e) => (e.field !== "first_column" ? (e.hidden = true) : (e.hidden = false)));
      }
    },
    formatLabel(el) {
      return this.$t(el);
    },
    getCategories() {
      if (this.columns.length) {
        this.columns.forEach((el) => {
          if (el.category && !this.categories.includes(el.category)) {
            this.categories.push(el.category);
          }
        });
      }
    },
  },
  created() {
    this.value = JSON.parse(JSON.stringify(this.columns));

    this.getCategories();
  },
};
</script>
<style lang="scss" scoped>
.title-h2 {
  margin-bottom: 10px;
}
.form-item__field {
  margin-bottom: 12px;
}
.btn {
  width: 100%;
}
.category-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  border-bottom: 1px solid #bababa;
  margin-bottom: 10px;
  padding: 7px 0;
}
.link {
  display: inline-block;
  font-weight: 600;
  color: #141414;
  border: 0;
  background-color: transparent;
  border-bottom: 1px dashed rgba(17, 68, 17, 0.267);
  padding: 0;
  outline: none;
  offset: none;
  background: none;
}

.check-box-block {
  display: flex;
  align-items: stretch;
  gap: 0 4px;
}

.checkSpan {
  position: relative;
  width: 20px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: #f3f4f9;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  cursor: pointer;

  &.active {
    background: #f0faf9;

    svg {
      fill: #38807d;
    }
  }

  &.disabled {
    background: #f5f5f5;

    svg {
      fill: #5d5d68;
    }
  }

  .check {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
}
</style>
